import { motion, Variants } from 'framer-motion';
import { createUseStyles, Styles } from 'react-jss';
import { colorPalette } from '../../Config/Theme';
import { useIsWideScreen } from '../../Hooks/ViewPortHook';

export enum ZoomDirection {
    LargeToSmall = 'largeToSmall',
    SmallToLarge = 'smallToLarge',
}

export interface StampMessageProps {
    text: string;
    zoomDirection?: ZoomDirection;
}

const stampVariants: Variants = {
    offscreen: {
        scaleX: 1,
        scaleY: 1,
        opacity: 0,
    },
    largeToSmall: {
        scaleX: [4, 1],
        scaleY: [4, 1],
        opacity: 1,
        transition: {
            duration: 0.5,
            ease: 'easeIn',
        },
    },
    smallToLarge: {
        scaleX: [0.25, 1],
        scaleY: [0.25, 1],
        opacity: 1,
        transition: {
            duration: 0.5,
            ease: 'easeIn',
        },
    },
};

export const StampMessage = ({ text, zoomDirection = ZoomDirection.LargeToSmall }: StampMessageProps) => {
    const { isPastSmallWidth } = useIsWideScreen();
    const style = useStyles({ isWideScreen: isPastSmallWidth });

    return (
        <motion.div
            className={style.messageContainer}
            variants={stampVariants}
            initial={'offscreen'}
            whileInView={zoomDirection}
            viewport={{ once: true }}
        >
            <div className={style.messageText}>{text}</div>
        </motion.div>
    );
};

const useStyles = createUseStyles(
    (): Styles<string, { isWideScreen: boolean }> => ({
        messageContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            background: colorPalette.lightGray,
        },
        messageText: {
            textTransform: 'uppercase',
            fontWeight: 'bold',
            padding: '.5rem 1rem',
            color: colorPalette.pureBlack,
            fontSize: ({ isWideScreen }) => (isWideScreen ? '3rem' : '2rem'),
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
        },
    }),
);
