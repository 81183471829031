import { useCallback, useMemo, useState } from 'react';
import { slide as Menu, State } from 'react-burger-menu';
import { createUseStyles, Styles } from 'react-jss';
import { Link, useLocation } from 'react-router-dom';
import { routes } from '../Config/Routes';
import { BaseTheme, colorPalette } from '../Config/Theme';
import { images } from '../Content/Images';
import { useIsWideScreen } from '../Hooks/ViewPortHook';
import { ImageWithCorrectAspectRatioStyle } from '../Utilities/LayoutUtilities';

export const Header = () => {
    const { isPastSmallWidth } = useIsWideScreen();
    const style = useStyles({ isWideScreen: isPastSmallWidth });

    const currentLocation = useLocation();

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const menuStylesSmall = useMemo(
        () => ({
            bmBurgerBars: {
                background: colorPalette.gold,
            },
            bmCrossButton: {
                height: '2rem',
                width: '2rem',
            },
            bmCross: {
                background: colorPalette.gold,
            },
            bmMenuWrap: {
                position: 'fixed',
                height: '100%',
                width: '25rem',
                left: '0px',
                top: '0px',
            },
            bmMenu: {
                background: 'linear-gradient(290deg, rgba(111,111,111,1) 25%, rgba(0,0,0,1) 95%)',
                padding: '2.5em 0',
            },
            bmOverlay: {
                background: 'rgba(0, 0, 0, 0.75)',
                left: '0px',
                top: '0px',
            },
            bmBurgerButton: {
                position: 'relative',
                width: '3rem',
                height: '2.5rem',
                left: '1.75rem',
            },
        }),
        [],
    );

    const closeMenu = () => {
        setIsMenuOpen(false);
    };

    const getLinkStyle = useCallback(
        (path: string) => {
            return currentLocation.pathname === path ? style.activeLink : style.link;
        },
        [currentLocation.pathname, style.activeLink, style.link],
    );

    const smallScreenMenu = useMemo(() => {
        return (
            <Menu
                styles={menuStylesSmall}
                isOpen={isMenuOpen}
                onStateChange={(state: State) => {
                    setIsMenuOpen(state.isOpen);
                }}
            >
                <Link to="" className={getLinkStyle(routes.Home)} onClick={closeMenu}>
                    {'Home'}
                </Link>
                <Link to={routes.Apparel} className={getLinkStyle(routes.Apparel)} onClick={closeMenu}>
                    {'Shop Apparel'}
                </Link>
                <Link to={routes.TrainingGear} className={getLinkStyle(routes.TrainingGear)} onClick={closeMenu}>
                    {'Shop Training Gear'}
                </Link>
                <Link to={routes.About} className={getLinkStyle(routes.About)} onClick={closeMenu}>
                    {'About'}
                </Link>
            </Menu>
        );
    }, [getLinkStyle, isMenuOpen, menuStylesSmall]);

    const smallScreenHeader = useMemo(() => {
        return (
            <div className={style.headerContainer}>
                <div className={style.leftContainer}>{smallScreenMenu}</div>
                <div className={style.centerContainer}>
                    <Link to="">
                        <img
                            src={images.logoSmall.src}
                            className={style.iconSmall}
                            width={images.logoSmall.width}
                            height={images.logoSmall.height}
                        />
                    </Link>
                </div>
                <div className={style.rightContainer}></div>
            </div>
        );
    }, [
        smallScreenMenu,
        style.centerContainer,
        style.headerContainer,
        style.iconSmall,
        style.leftContainer,
        style.rightContainer,
    ]);

    const createMenuItem = useCallback(
        (route: string, label: string) => {
            return (
                <div className={style.wideMenuLinkContainer}>
                    <Link to={route} className={style.wideMenuLink}>
                        {label}
                    </Link>
                </div>
            );
        },
        [style.wideMenuLink, style.wideMenuLinkContainer],
    );

    const wideScreenMenu = useMemo(() => {
        return (
            <div className={style.wideMenuContainer}>
                <img
                    src={images.logoSmall.src}
                    className={style.iconWide}
                    width={images.logoSmall.width}
                    height={images.logoSmall.height}
                />
                {createMenuItem(routes.Home, 'Home')}
                {createMenuItem(routes.Apparel, 'Apparel')}
                {createMenuItem(routes.TrainingGear, 'Training Gear')}
                {createMenuItem(routes.About, 'About')}
            </div>
        );
    }, [createMenuItem, style.iconWide, style.wideMenuContainer]);

    const wideScreenHeader = useMemo(() => {
        return (
            <div className={style.headerContainerWide}>
                <nav className={style.centerContainerWide}>{wideScreenMenu}</nav>
            </div>
        );
    }, [style.centerContainerWide, style.headerContainerWide, wideScreenMenu]);

    const header = useMemo(() => {
        return isPastSmallWidth ? wideScreenHeader : smallScreenHeader;
    }, [isPastSmallWidth, smallScreenHeader, wideScreenHeader]);

    return (
        <div className={style.container}>
            {isPastSmallWidth && (
                <div className={style.titleContainer}>
                    <img src={images.title.src} width={images.title.width} height={images.title.height} />
                </div>
            )}
            {header}
        </div>
    );
};

const useStyles = createUseStyles(
    (theme: BaseTheme): Styles<string, { isWideScreen: boolean }> => ({
        container: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100vw',
        },
        headerContainer: {
            backgroundColor: colorPalette.pureBlack,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: '100%',
            height: '6rem',
        },
        headerContainerWide: {
            backgroundColor: colorPalette.lightGray,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '4rem',
            marginBottom: '.25rem',
        },
        titleContainer: {
            backgroundColor: colorPalette.pureBlack,
            width: '100vw',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
        },
        titleImage: {
            ...ImageWithCorrectAspectRatioStyle,
        },
        titleLarge: {
            fontSize: '2rem',
            fontWeight: 'bold',
            justifyContent: 'center',
            display: 'flex',
            color: colorPalette.gold,
            textTransform: 'uppercase',
        },
        titleSmall: {
            fontSize: '1rem',
            fontWeight: 'bold',
            justifyContent: 'center',
            display: 'flex',
            color: colorPalette.gold,
            paddingBottom: '.5rem',
            textTransform: 'uppercase',
        },
        leftContainer: {
            flex: 1,
        },
        centerContainer: {
            display: 'flex',
            flex: 2,
            justifyContent: 'center',
        },
        rightContainer: {
            flex: 1,
        },
        sideContainerWide: {
            width: '10rem',
        },
        sideContainerSpacer: {
            width: '2rem',
        },
        centerContainerWide: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'end',
            height: '100%',
        },
        iconSmall: {
            height: '5rem',
            width: 'auto',
            aspectRatio: '1/1',
        },
        iconWide: {
            height: '4rem',
            width: 'auto',
            aspectRatio: '1/1',
            marginRight: '1rem',
        },
        link: {
            borderBottom: '2px solid white',
            padding: '1rem 2rem',
            display: 'flex',
            fontSize: '1.25rem',
            fontWeight: 'bold',
            color: theme.text.secondaryColor,
            textDecoration: 'none',
            alignSelf: 'center',
            transition: 'color 0.25s',
            '&:hover': {
                color: theme.text.primaryColor,
            },
            whiteSpace: 'nowrap',
        },
        activeLink: {
            composes: '$link',
            color: theme.text.primaryColor,
            borderColor: theme.text.primaryColor,
        },
        wideMenuLinkContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: '0 0.5rem',
        },
        wideMenuLink: {
            fontSize: '1.25rem',
            fontWeight: 'bold',
            padding: '0 .5rem',
            textDecoration: 'none',
            color: colorPalette.pureBlack,
            transition: 'color 0.25s',
            '&:hover': {
                color: colorPalette.gold,
                '&:after': {
                    content: '""',
                    display: 'block',
                    width: '100%',
                    height: '2px',
                    marginTop: '0.5rem',
                    backgroundColor: colorPalette.gold,
                    transformOrigin: 'center top',
                    transform: 'scale(1, 1)',
                    transition: 'transform 0.25s ease-out',
                },
            },
            '&:after': {
                content: '""',
                display: 'block',
                width: '100%',
                height: '2px',
                marginTop: '0.5rem',
                backgroundColor: 'transparent',
                transformOrigin: 'center top',
                transform: 'scale(0, 1)',
                transition: 'transform 0.25s ease-out',
            },
        },
        wideMenuContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
    }),
);
