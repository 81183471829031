import { createUseStyles, Styles } from 'react-jss';
import { BaseTheme } from '../Config/Theme';
import { images } from '../Content/Images';
import { useIsWideScreen } from '../Hooks/ViewPortHook';
import { ImageWithCorrectAspectRatioStyle } from '../Utilities/LayoutUtilities';

export const TrainingProgram = () => {
    const { isPastSmallWidth } = useIsWideScreen();
    const style = useStyles({ isWideScreen: isPastSmallWidth });

    return (
        <div className={style.container}>
            <img
                src={images.banner.src}
                className={style.banner}
                width={images.banner.width}
                height={images.banner.height}
            />
            <h1 className={style.header}>Get a different banner image for training program</h1>
            <h1 className={style.header}>Jiu jitsu Fitness Program</h1>
            <p className={style.descriptionText}>
                Some description about JJ fitness program. Images, videos, etc. Some Lorem Ipsum text below.
            </p>
            <p className={style.loremIpsum}>Lorem Ipsum is just placeholder text</p>
            <p className={style.descriptionText}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque placerat ac lacus ullamcorper
                pellentesque. Duis ullamcorper mauris vitae augue vehicula ornare non ac eros. Phasellus consectetur
                orci est, in rhoncus dui porttitor a. Fusce orci tellus, pulvinar eget odio eu, sagittis aliquet erat.
                Praesent maximus ligula nec metus pellentesque interdum. In luctus efficitur turpis, at luctus turpis
                congue ut. Maecenas scelerisque justo eget urna condimentum, bibendum pharetra tortor finibus.
                Vestibulum luctus at est eget dapibus. Sed cursus suscipit leo, sit amet molestie arcu consectetur in.
                Proin eu eleifend tortor, sed pretium neque. Etiam tempus sem vel elit accumsan, eget cursus libero
                dignissim. Duis justo sapien, mollis id lacinia sed, consectetur a leo. Curabitur volutpat ornare nibh,
                sed sollicitudin enim fermentum quis.
            </p>
            <p className={style.descriptionText}>
                Nunc eu est odio. Vivamus accumsan convallis eros, a aliquet nisi varius vitae. Nulla ultrices lacinia
                metus, at consequat dui rutrum sed. Mauris feugiat tortor vitae aliquam tincidunt. Curabitur fringilla
                purus ut urna sollicitudin sollicitudin. Maecenas luctus sit amet ante ac condimentum. Duis porttitor
                rutrum interdum.
            </p>
            <p className={style.descriptionText}>
                Cras efficitur egestas eros, at convallis arcu auctor eu. Cras varius metus metus, eu porta orci
                pellentesque sed. Nullam interdum ipsum varius odio semper condimentum. Maecenas sodales fringilla
                mauris quis consectetur. Suspendisse ullamcorper lacus in dignissim varius. Praesent velit augue, mollis
                at est ac, suscipit ultrices sapien. In ultricies felis vitae viverra ullamcorper. Phasellus sit amet
                malesuada sapien. Praesent ac posuere enim. In quis posuere ipsum. Curabitur ut felis hendrerit,
                facilisis nisi vitae, ultricies nibh. Sed consequat nibh vitae imperdiet consectetur. Pellentesque
                aliquam at velit eu ullamcorper. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            </p>
            <h1 id="intro" className={style.header}>
                Intro Training Program
            </h1>
            <p className={style.descriptionText}>Some description about intro training program. Images, videos, etc.</p>
            <p className={style.descriptionText}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque placerat ac lacus ullamcorper
                pellentesque. Duis ullamcorper mauris vitae augue vehicula ornare non ac eros. Phasellus consectetur
                orci est, in rhoncus dui porttitor a. Fusce orci tellus, pulvinar eget odio eu, sagittis aliquet erat.
                Praesent maximus ligula nec metus pellentesque interdum. In luctus efficitur turpis, at luctus turpis
                congue ut. Maecenas scelerisque justo eget urna condimentum, bibendum pharetra tortor finibus.
                Vestibulum luctus at est eget dapibus. Sed cursus suscipit leo, sit amet molestie arcu consectetur in.
                Proin eu eleifend tortor, sed pretium neque. Etiam tempus sem vel elit accumsan, eget cursus libero
                dignissim. Duis justo sapien, mollis id lacinia sed, consectetur a leo. Curabitur volutpat ornare nibh,
                sed sollicitudin enim fermentum quis.
            </p>
            <p className={style.descriptionText}>
                Nunc eu est odio. Vivamus accumsan convallis eros, a aliquet nisi varius vitae. Nulla ultrices lacinia
                metus, at consequat dui rutrum sed. Mauris feugiat tortor vitae aliquam tincidunt. Curabitur fringilla
                purus ut urna sollicitudin sollicitudin. Maecenas luctus sit amet ante ac condimentum. Duis porttitor
                rutrum interdum.
            </p>
            <p className={style.descriptionText}>
                Cras efficitur egestas eros, at convallis arcu auctor eu. Cras varius metus metus, eu porta orci
                pellentesque sed. Nullam interdum ipsum varius odio semper condimentum. Maecenas sodales fringilla
                mauris quis consectetur. Suspendisse ullamcorper lacus in dignissim varius. Praesent velit augue, mollis
                at est ac, suscipit ultrices sapien. In ultricies felis vitae viverra ullamcorper. Phasellus sit amet
                malesuada sapien. Praesent ac posuere enim. In quis posuere ipsum. Curabitur ut felis hendrerit,
                facilisis nisi vitae, ultricies nibh. Sed consequat nibh vitae imperdiet consectetur. Pellentesque
                aliquam at velit eu ullamcorper. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            </p>
            <h1 id="competition" className={style.header}>
                Competition Training Program
            </h1>
            <p className={style.descriptionText}>
                Some description about competition training program. Images, videos, etc.
            </p>
            <p className={style.descriptionText}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque placerat ac lacus ullamcorper
                pellentesque. Duis ullamcorper mauris vitae augue vehicula ornare non ac eros. Phasellus consectetur
                orci est, in rhoncus dui porttitor a. Fusce orci tellus, pulvinar eget odio eu, sagittis aliquet erat.
                Praesent maximus ligula nec metus pellentesque interdum. In luctus efficitur turpis, at luctus turpis
                congue ut. Maecenas scelerisque justo eget urna condimentum, bibendum pharetra tortor finibus.
                Vestibulum luctus at est eget dapibus. Sed cursus suscipit leo, sit amet molestie arcu consectetur in.
                Proin eu eleifend tortor, sed pretium neque. Etiam tempus sem vel elit accumsan, eget cursus libero
                dignissim. Duis justo sapien, mollis id lacinia sed, consectetur a leo. Curabitur volutpat ornare nibh,
                sed sollicitudin enim fermentum quis.
            </p>
            <p className={style.descriptionText}>
                Nunc eu est odio. Vivamus accumsan convallis eros, a aliquet nisi varius vitae. Nulla ultrices lacinia
                metus, at consequat dui rutrum sed. Mauris feugiat tortor vitae aliquam tincidunt. Curabitur fringilla
                purus ut urna sollicitudin sollicitudin. Maecenas luctus sit amet ante ac condimentum. Duis porttitor
                rutrum interdum.
            </p>
            <p className={style.descriptionText}>
                Cras efficitur egestas eros, at convallis arcu auctor eu. Cras varius metus metus, eu porta orci
                pellentesque sed. Nullam interdum ipsum varius odio semper condimentum. Maecenas sodales fringilla
                mauris quis consectetur. Suspendisse ullamcorper lacus in dignissim varius. Praesent velit augue, mollis
                at est ac, suscipit ultrices sapien. In ultricies felis vitae viverra ullamcorper. Phasellus sit amet
                malesuada sapien. Praesent ac posuere enim. In quis posuere ipsum. Curabitur ut felis hendrerit,
                facilisis nisi vitae, ultricies nibh. Sed consequat nibh vitae imperdiet consectetur. Pellentesque
                aliquam at velit eu ullamcorper. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            </p>
        </div>
    );
};

const useStyles = createUseStyles(
    (theme: BaseTheme): Styles<string, { isWideScreen: boolean }> => ({
        container: {
            display: 'flex',
            flexDirection: 'column',
        },
        banner: {
            ...ImageWithCorrectAspectRatioStyle,
        },
        header: {
            color: theme.text.secondaryColor,
            fontSize: ({ isWideScreen }) => (isWideScreen ? '4rem' : '2rem'),
            fontWeight: 'bold',
        },
        descriptionText: {
            color: theme.text.primaryColor,
            fontSize: ({ isWideScreen }) => (isWideScreen ? '2rem' : '1.5rem'),
        },
        loremIpsum: {
            color: 'red',
            fontWeight: 'bold',
            fontSize: ({ isWideScreen }) => (isWideScreen ? '2rem' : '1.5rem'),
        },
    }),
);
