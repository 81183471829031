import { useCallback } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { TransitionImagesAndLink, TransitionImagesAndLinkProps } from './TransitionImagesAndLink';

export interface ItemsCarouselProps {
    items: TransitionImagesAndLinkProps[];
}

export const ItemsCarousel = ({ items }: ItemsCarouselProps) => {
    const getItems = useCallback(() => {
        return items.map((item, index) => {
            return <TransitionImagesAndLink {...item} key={index} />;
        });
    }, [items]);

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
        },
        tablet: {
            breakpoint: { max: 1024, min: 500 },
            items: 2,
        },
        mobile: {
            breakpoint: { max: 500, min: 0 },
            items: 1,
        },
    };
    return (
        <Carousel
            responsive={responsive}
            swipeable={true}
            draggable={false}
            showDots={false}
            infinite={true}
            autoPlay={false}
            autoPlaySpeed={5000}
            keyBoardControl={false}
            customTransition="all .5"
            transitionDuration={1000}
            containerClass="carousel-container"
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px"
        >
            {getItems()}
        </Carousel>
    );
};
