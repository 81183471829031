import { createUseStyles } from 'react-jss';
import { Outlet } from 'react-router';
import { Footer } from '../Components/Footer';
import { Header } from '../Components/Header';
import { BaseTheme } from '../Config/Theme';

export const Main = () => {
    const style = useStyles();

    return (
        <div className={style.container}>
            <div className={style.headerContainer}>
                <div className={style.headerAndFooterContent}>
                    <Header />
                </div>
            </div>
            <div className={style.contentContainer}>
                <div className={style.content}>
                    <Outlet />
                </div>
            </div>
            <div className={style.footerContainer}>
                <div className={style.headerAndFooterContent}>
                    <Footer />
                </div>
            </div>
        </div>
    );
};

const useStyles = createUseStyles((theme: BaseTheme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        overflowX: 'hidden',
        width: '100vw',
        backgroundColor: theme.background.static,
    },
    headerContainer: {
        display: 'flex',
        width: '100%',
    },
    contentContainer: {
        display: 'flex',
        width: '100%',
    },
    footerContainer: {
        display: 'flex',
        width: '100%',
    },
    headerAndFooterContent: {
        display: 'flex',
        width: '100%',
        margin: 'auto',
    },
    content: {
        display: 'flex',
        width: '100%',
    },
}));
