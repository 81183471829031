export const images = {
    title: { src: 'Images/title.png', width: 600, height: 80 },
    logo: { src: 'Images/logo.png', width: 700, height: 700 },
    logoSmall: { src: 'Images/logo-small.png', width: 380, height: 380 },
    facebook: { src: 'Images/facebook.png', width: 512, height: 512 },
    instagram: { src: 'Images/instagram.png', width: 512, height: 512 },
    banner: { src: 'Images/banner.png', width: 2100, height: 720 },
    apparel: { src: 'Images/apparel.png', width: 600, height: 600 },
    trainingGear: { src: 'Images/training-gear.png', width: 600, height: 600 },
    trainingGearLongFront: { src: 'Images/training-gear-long-front.png', width: 600, height: 1200 },
    trainingGearLongBack: { src: 'Images/training-gear-long-back.png', width: 600, height: 1200 },
    trainingGearLongBlackFront: { src: 'Images/training-gear-long-black-front.png', width: 600, height: 1200 },
    trainingGearLongBlackBack: { src: 'Images/training-gear-long-black-back.png', width: 600, height: 1200 },
    trainingProgramIntro: { src: 'Images/training-program-intro.png', width: 600, height: 600 },
    trainingProgramCompetition: { src: 'Images/training-program-competition.png', width: 600, height: 600 },
    trainingProgramBackground: { src: 'Images/training-program-background.png', width: 851, height: 317 },
    gear1: { src: 'Images/gear-1.png', width: 300, height: 400 },
    gear2: { src: 'Images/gear-2.png', width: 600, height: 400 },
    hoodieBack: { src: 'Images/hoodie-back.jpg', width: 1000, height: 1400 },
    hoodieFront: { src: 'Images/hoodie-front.jpg', width: 1000, height: 1400 },
    hoodieSide: { src: 'Images/hoodie-side.jpg', width: 1000, height: 1400 },
};

const apparelPath = (path: string) => `Images/Apparel/${path}`;

export const apparelImages = {
    // Hoodies
    hoodie1Back1: { src: apparelPath('hoodie-1-back-1.jpg'), width: 1000, height: 1400 },
    hoodie1Front1: { src: apparelPath('hoodie-1-front-1.jpg'), width: 1000, height: 1400 },
    hoodie1Side1: { src: apparelPath('hoodie-1-side-1.jpg'), width: 1000, height: 1400 },
    hoodie2Back1: { src: apparelPath('hoodie-2-back-1.jpg'), width: 1000, height: 1400 },
    hoodie2Front1: { src: apparelPath('hoodie-2-front-1.jpg'), width: 1000, height: 1400 },
    hoodie2Front2: { src: apparelPath('hoodie-2-front-2.jpg'), width: 1000, height: 1400 },
    // Shirts
    shirt1Front1: { src: apparelPath('shirt-1-front-1.jpg'), width: 1000, height: 1400 },
    shirt1Front2: { src: apparelPath('shirt-1-front-2.jpg'), width: 1000, height: 1400 },
    shirt1Side1: { src: apparelPath('shirt-1-side-1.jpg'), width: 1000, height: 1400 },
    shirt2Front1: { src: apparelPath('shirt-2-front-1.jpg'), width: 1000, height: 1400 },
    shirt2Front2: { src: apparelPath('shirt-2-front-2.jpg'), width: 1000, height: 1400 },
};

const trainingGearPath = (path: string) => `Images/TrainingGear/${path}`;
export const trainingGearImages = {
    // No Gi Shorts
    noGiShorts1Front1: { src: trainingGearPath('no-gi-shorts-1-front-1.jpg'), width: 1000, height: 1400 },
    noGiShorts1Side1: { src: trainingGearPath('no-gi-shorts-1-side-1.jpg'), width: 1000, height: 1400 },
    // Rash Guards
    rashGuardLong1Back1: { src: trainingGearPath('rash-guard-long-1-back-1.jpg'), width: 1000, height: 1400 },
    rashGuardLong1Front1: { src: trainingGearPath('rash-guard-long-1-front-1.jpg'), width: 1000, height: 1400 },
    rashGuardShort1Back1: { src: trainingGearPath('rash-guard-short-1-back-1.jpg'), width: 1000, height: 1400 },
    rashGuardShort1Front1: { src: trainingGearPath('rash-guard-short-1-front-1.jpg'), width: 1000, height: 1400 },
};

export interface ImageWithDimensions {
    src: string;
    width: number;
    height: number;
}
