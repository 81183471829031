import { useEffect, useState } from 'react';

const SmallScreenWidth = 832;
const MediumScreenWidth = 1024;

export interface ScreenBreaks {
    isPastSmallWidth: boolean;
    isPastMediumWidth: boolean;
}

export const useIsWideScreen = (): ScreenBreaks => {
    const [width, setWidth] = useState(window.innerWidth);

    useEffect((): (() => void) => {
        const handleWindowResize = (): void => {
            const newWidth = window.innerWidth;
            const newIsWideSmall = newWidth >= SmallScreenWidth;
            const oldIsWideSmall = width >= SmallScreenWidth;
            const newIsWideMedium = newWidth >= MediumScreenWidth;
            const oldIsWideMedium = width >= MediumScreenWidth;
            if (newIsWideSmall !== oldIsWideSmall || newIsWideMedium !== oldIsWideMedium) {
                setWidth(window.innerWidth);
            }
        };
        window.addEventListener('resize', handleWindowResize);
        return (): void => window.removeEventListener('resize', handleWindowResize);
    }, [width]);

    return { isPastSmallWidth: width >= SmallScreenWidth, isPastMediumWidth: width > MediumScreenWidth };
};
