import { TransitionImagesAndLinkProps } from '../Components/Shared/TransitionImagesAndLink';
import { apparelImages, trainingGearImages } from '../Content/Images';
import { routes } from './Routes';

export const carouselItems: { [key: string]: TransitionImagesAndLinkProps[] } = {
    apparel: [
        {
            images: [
                apparelImages.hoodie1Front1,
                apparelImages.hoodie1Back1,
                apparelImages.hoodie1Side1,
                apparelImages.hoodie2Front1,
                apparelImages.hoodie2Front2,
                apparelImages.hoodie2Back1,
            ],
            text: 'Hoodies',
            link: routes.TrainingGear,
            linkPosition: 'bottom',
        },
        {
            images: [
                apparelImages.shirt1Front1,
                apparelImages.shirt1Front2,
                apparelImages.shirt1Side1,
                apparelImages.shirt2Front1,
                apparelImages.shirt2Front2,
            ],
            text: 'Shirts',
            link: routes.TrainingGear,
            linkPosition: 'bottom',
        },
    ],
    trainingGear: [
        {
            images: [
                trainingGearImages.rashGuardLong1Front1,
                trainingGearImages.rashGuardLong1Back1,
                trainingGearImages.rashGuardShort1Front1,
                trainingGearImages.rashGuardShort1Back1,
            ],
            text: 'Rash Guards',
            link: routes.TrainingGear,
            linkPosition: 'bottom',
        },
        {
            images: [trainingGearImages.noGiShorts1Front1, trainingGearImages.noGiShorts1Side1],
            text: 'No Gi Shorts',
            link: routes.TrainingGear,
            linkPosition: 'bottom',
        },
    ],
};
