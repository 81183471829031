import { createBrowserRouter } from 'react-router-dom';
import { routes } from '../Config/Routes';
import { About } from './About';
import { Apparel } from './Apparel';
import { Home } from './Home';
import { Main } from './Main';
import { TrainingGear } from './TrainingGear';
import { TrainingProgram } from './TrainingProgram';

export const router = createBrowserRouter([
    {
        path: '/',
        element: <Main />,
        children: [
            {
                path: routes.Home,
                element: <Home />,
            },
            {
                path: routes.Apparel,
                element: <Apparel />,
            },
            {
                path: routes.TrainingGear,
                element: <TrainingGear />,
            },
            {
                path: routes.TrainingProgram,
                element: <TrainingProgram />,
            },
            {
                path: routes.About,
                element: <About />,
            },
        ],
    },
]);
