import { motion } from 'framer-motion';
import { useState } from 'react';
import { createUseStyles, Styles } from 'react-jss';
import { HashLink } from 'react-router-hash-link';
import { colorPalette } from '../../Config/Theme';
import { ImageWithDimensions } from '../../Content/Images';
import { Position } from '../../Model/Position';
import { ImageWithCorrectAspectRatioStyle } from '../../Utilities/LayoutUtilities';

export interface TransitionImagesAndLinkProps {
    images: ImageWithDimensions[];
    text: string;
    link: string;
    linkPosition: Position;
    itemContainerClassName?: string;
    linkTextWidth?: string;
    transitionDurationMs?: number;
    fadeDurationS?: number;
}

export const TransitionImagesAndLink = ({
    images,
    text,
    link,
    linkPosition,
    itemContainerClassName,
    linkTextWidth = '50%',
    transitionDurationMs = 5000,
    fadeDurationS = 2,
}: TransitionImagesAndLinkProps) => {
    const style = useStyles({ linkPosition, linkTextWidth });
    const [imageIndex, setImageIndex] = useState(0);
    const [isHovered, setIsHovered] = useState(false);

    setTimeout(() => {
        if (!isHovered) {
            setImageIndex((imageIndex + 1) % images.length);
        }
    }, transitionDurationMs);

    const variants = {
        enter: () => {
            return {
                opacity: 0,
            };
        },
        visible: () => {
            return {
                opacity: 1,
            };
        },
        exit: () => {
            return {
                opacity: 0,
            };
        },
    };

    return (
        <HashLink to={link} className={style.link}>
            {linkPosition === 'top' && (
                <div className={style.textContainer}>
                    <div className={style.text}>{text}</div>
                </div>
            )}
            <div className={`${style.itemContainer} ${itemContainerClassName}`}>
                <motion.img
                    key={imageIndex}
                    src={images[imageIndex].src}
                    className={style.image}
                    width={images[imageIndex].width}
                    height={images[imageIndex].height}
                    alt={images[imageIndex].src}
                    variants={variants}
                    initial="enter"
                    animate="visible"
                    exit="exit"
                    transition={{
                        opacity: { duration: fadeDurationS },
                    }}
                    whileHover={{ scale: 1.05, transition: { duration: 0.25 } }}
                    onHoverStart={() => setIsHovered(true)}
                    onHoverEnd={() => setIsHovered(false)}
                />
                {linkPosition === 'insideTop' && (
                    <div className={style.textContainer}>
                        <div className={style.text}>{text}</div>
                    </div>
                )}
                {(linkPosition === 'insideBottom' || linkPosition === 'center') && (
                    <div className={style.textContainer}>
                        <div className={style.text}>{text}</div>
                    </div>
                )}
            </div>
            {linkPosition === 'bottom' && (
                <div className={style.textContainer}>
                    <div className={style.text}>{text}</div>
                </div>
            )}
        </HashLink>
    );
};

const useStyles = createUseStyles(
    (): Styles<string, { linkPosition: Position; linkTextWidth: string; border?: boolean }> => ({
        link: {
            textDecoration: 'none',
        },
        itemContainer: {
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
            margin: '1rem',
            overflow: 'hidden',
            border: `0.375rem solid transparent`,
            '&:hover': {
                border: `0.375rem solid ${colorPalette.gold}`,
            },
        },
        image: {
            ...ImageWithCorrectAspectRatioStyle,
        },
        textContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '1.5rem',
            fontWeight: 'bold',
            textAlign: 'center',
            whiteSpace: 'nowrap',
            left: ({ linkPosition }) =>
                linkPosition === 'center' || linkPosition === 'insideBottom' || linkPosition === 'insideTop'
                    ? '1%'
                    : undefined,
            right: ({ linkPosition }) =>
                linkPosition === 'center' || linkPosition === 'insideBottom' || linkPosition === 'insideTop'
                    ? '1%'
                    : undefined,
            top: ({ linkPosition }) => {
                switch (linkPosition) {
                    case 'center':
                        return '45%';
                    case 'insideTop':
                        return '1%';
                    case 'insideBottom':
                        return '80%';
                    default:
                        return undefined;
                }
            },
            bottom: ({ linkPosition }) => {
                switch (linkPosition) {
                    case 'center':
                        return '45%';
                    case 'insideTop':
                        return '80%';
                    case 'insideBottom':
                        return '1%';
                    default:
                        return undefined;
                }
            },
            position: ({ linkPosition }) =>
                linkPosition === 'center' || linkPosition === 'insideBottom' || linkPosition === 'insideTop'
                    ? 'absolute'
                    : 'relative',
        },
        text: {
            width: ({ linkTextWidth }) => linkTextWidth,
            padding: '.25rem',
            color: colorPalette.gold,
        },
    }),
);
