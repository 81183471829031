import { useEffect } from 'react';
import { createUseStyles, Styles } from 'react-jss';
import { ItemsCarousel } from '../Components/Shared/ItemsCarousel';
import { StampMessage } from '../Components/Shared/StampMessage';
import { carouselItems } from '../Config/CarouselItems';
import { BaseTheme, colorPalette } from '../Config/Theme';
import { videos } from '../Content/Videos';
import { useIsWideScreen } from '../Hooks/ViewPortHook';
import { ImageWithCorrectAspectRatioStyle } from '../Utilities/LayoutUtilities';

export const Home = () => {
    const { isPastSmallWidth } = useIsWideScreen();
    const style = useStyles({ isWideScreen: isPastSmallWidth });

    useEffect(() => {
        const loadScript = async () => {
            return new Promise((resolve) => {
                const script = document.createElement('script');
                script.src = 'https://cdn.curator.io/published/e27ce053-8344-4294-be19-2173999f453e.js';
                document.body.appendChild(script);

                script.onload = () => {
                    // add a small timeout to give it time to load
                    setTimeout(() => {
                        resolve(true);
                    }, 1000);
                };
            });
        };

        setTimeout(() => {
            loadScript();
        }, 1000);
    }, []);

    return (
        <div className={style.container}>
            <div className={style.videoContainer}>
                <video
                    src={videos.intro}
                    autoPlay={true}
                    loop={true}
                    controls={false}
                    className={style.video}
                    playsInline={true}
                    muted={true}
                    controlsList={'nodownload nofullscreen'}
                    disablePictureInPicture={true}
                />
            </div>
            <div className={style.productsContainer}>
                <StampMessage text="Apparel" />
            </div>
            <div className={style.carouselContainer}>
                <ItemsCarousel items={carouselItems.apparel} />
            </div>
            <div className={style.productsContainer}>
                <StampMessage text="Training Gear" />
            </div>
            <div className={style.carouselContainer}>
                <ItemsCarousel items={carouselItems.trainingGear} />
            </div>
            <div className={style.curatorContainer}>
                <div id="curator-feed-default-feed-layout">
                    <a href="https://curator.io" rel="noreferrer" target="_blank" className="crt-logo crt-tag">
                        Powered by Curator.io
                    </a>
                </div>
            </div>
        </div>
    );
};

const useStyles = createUseStyles(
    (theme: BaseTheme): Styles<string, { isWideScreen: boolean }> => ({
        container: {
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            width: '100%',
        },
        carouselContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            overflow: 'hidden',
            padding: '1rem',
            maxWidth: theme.size.maxWidth,
            width: '100%',
            alignSelf: 'center',
        },
        title: {
            color: theme.text.primaryColor,
        },
        banner: {
            ...ImageWithCorrectAspectRatioStyle,
        },
        productsContainer: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'space-around',
        },
        imageContainer: {
            display: 'flex',
            flexDirection: 'column',
            border: '4px solid red',
            padding: '1rem',
            margin: '1rem',
            alignItems: 'center',
            '&:hover': {
                border: '4px solid white',
                cursor: 'pointer',
            },
            width: ({ isWideScreen }) => (isWideScreen ? '40vw' : '75vw'),
            textDecoration: 'none',
        },
        image: {
            width: '100%',
        },
        imageText: {
            color: 'white',
            fontSize: '1.5rem',
            fontWeight: 'bold',
            textAlign: 'center',
        },
        curatorContainer: {
            display: 'flex',
            justifyContent: 'center',
            background: colorPalette.pureBlack,
            marginTop: '2rem',
        },
        videoContainer: {
            display: 'flex',
            justifyContent: 'center',
            background: colorPalette.pureBlack,
        },
        video: {
            maxWidth: '50rem',
            width: '100%',
            aspectRatio: '16/9',
        },
    }),
);
