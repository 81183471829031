import { createUseStyles } from 'react-jss';
import { Link } from 'react-router-dom';
import { BaseTheme, colorPalette } from '../Config/Theme';
import { images } from '../Content/Images';

export const Footer = () => {
    const style = useStyles();

    return (
        <div className={style.container}>
            <div className={style.socialMediaContainer}>
                <Link
                    to="mailto:support@lastkingsjj.com?subject=Last%20Kings%20JJ"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={style.email}
                >
                    <span>mail</span>
                </Link>
                <Link to="https://www.instagram.com/olaguebjj" target="_blank" rel="noopener noreferrer">
                    <img
                        src={images.instagram.src}
                        className={style.icon}
                        width={images.instagram.width}
                        height={images.instagram.height}
                    ></img>
                </Link>
                <Link to="https://www.facebook.com/bryan.olague.5" target="_blank" rel="noopener noreferrer">
                    <img
                        src={images.facebook.src}
                        className={style.icon}
                        width={images.facebook.width}
                        height={images.facebook.height}
                    ></img>
                </Link>
            </div>
            <div className={style.copyrightContainer}>
                <span className={style.symbols}>copyright</span>
                <span className={style.text}>2023, Last Kings Jiu Jitsu</span>
            </div>
        </div>
    );
};

const useStyles = createUseStyles((theme: BaseTheme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        padding: '1rem',
        backgroundColor: colorPalette.pureBlack,
    },
    socialMediaContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    copyrightContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingTop: '.5rem',
    },
    logo: {
        width: '8rem',
        height: '8rem',
    },
    text: {
        display: 'flex',
        color: theme.text.primaryColor,
        paddingLeft: '.25rem',
    },
    symbols: {
        composes: '$text',
        fontFamily: 'Material Symbols Outlined',
    },
    email: {
        composes: '$symbols',
        textDecoration: 'none',
        fontSize: '2.25rem',
        padding: '0 1rem',
    },
    icon: {
        width: '2rem',
        height: 'auto',
        aspectRatio: 'attr(width) / attr(height)',
        margin: '0 1rem',
    },
}));
