import { createUseStyles } from 'react-jss';
import { BaseTheme } from '../Config/Theme';

export const TrainingGear = () => {
    const style = useStyles();

    return (
        <div className={style.container}>
            <div className={style.title}>Training Gear</div>
        </div>
    );
};

const useStyles = createUseStyles((theme: BaseTheme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
    },
    title: {
        color: theme.text.primaryColor,
    },
}));
