export const messages = {
    missionStatement:
        'At Last Kings Jiujitsu, our mission is to empower ordinary individuals to ascend to greatness, cultivating ' +
        'their inner potential and transforming them into kings of self-discipline, resilience, and martial ' +
        'prowess. Through dedicated training, unwavering commitment, and a supportive community, we forge warriors ' +
        'who not only conquer the mat but also conquer their own limitations, ascending to the throne of their ' +
        'personal excellence.',
    aboutBryan:
        'Bryan is a BJJ black belt under Ricardo Barros... ' + 'Bryan come up with some more stuff about yourself.',
    contactInfo: 'Any contact info you want to put here. Email, phone number, address, etc.',
};
