import { createUseStyles, Styles } from 'react-jss';
import { StampMessage } from '../Components/Shared/StampMessage';
import { BaseTheme, colorPalette } from '../Config/Theme';
import { images } from '../Content/Images';
import { messages } from '../Content/Messages';
import { useIsWideScreen } from '../Hooks/ViewPortHook';
import { ImageWithCorrectAspectRatioStyle } from '../Utilities/LayoutUtilities';

export const About = () => {
    const { isPastSmallWidth } = useIsWideScreen();
    const style = useStyles({ isWideScreen: isPastSmallWidth });

    return (
        <div className={style.container}>
            <img
                src={images.banner.src}
                className={style.banner}
                width={images.banner.width}
                height={images.banner.height}
            />
            <StampMessage text="About Us" />
            <div className={style.contentContainer}>
                <div className={style.contentSection}>
                    <h1 className={style.header}>Our Mission:</h1>
                    <p className={style.descriptionText}>{messages.missionStatement}</p>
                </div>
                <div className={style.contentSection}>
                    <h1 className={style.header}>About Bryan Olague:</h1>
                    <p className={style.descriptionText}>{messages.aboutBryan}</p>
                </div>
                <div className={style.contentSection}>
                    <h1 className={style.header}>Contact Info:</h1>
                    <p className={style.descriptionText}>{messages.contactInfo}</p>
                </div>
            </div>
        </div>
    );
};

const useStyles = createUseStyles(
    (theme: BaseTheme): Styles<string, { isWideScreen: boolean }> => ({
        container: {
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: colorPalette.pureBlack,
        },
        banner: {
            ...ImageWithCorrectAspectRatioStyle,
        },
        header: {
            color: theme.text.secondaryColor,
            fontSize: ({ isWideScreen }) => (isWideScreen ? '2rem' : '2rem'),
            fontWeight: 'bold',
        },
        descriptionText: {
            color: theme.text.primaryColor,
            fontSize: ({ isWideScreen }) => (isWideScreen ? '1.25rem' : '1.5rem'),
        },
        contentContainer: {
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignSelf: 'center',
        },
        contentSection: {
            display: 'flex',
            flexDirection: 'column',
            maxWidth: theme.size.maxWidth,
            padding: '2rem',
        },
    }),
);
